* {
    box-sizing: inherit;
}

html {
    height: 100%; /* Fallback CSS for IE 4-6 and older browsers. Note: Without this setting, body below cannot achieve 100% height. */
    height: 100vh;/* Overrides 100% height in modern HTML5 browsers and uses the viewport's height. Only works in modern HTML5 browsers */
}

body {
    
    height: 100%; /* Starts web page with 100% height. Fallback for IE 4-6 and older browsers */
    height: 100vh;/* Starts web page with 100% height. Uses the viewport's height. Only works in modern HTML5 browsers */
    margin: 0;
    padding: 0;
     font-family: "Alef", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    background: #f9f9f9;
}

#root {
  min-height: 100%;
  height: 100%;
}


body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 22px;
}

.css-xrpg5q-MuiTypography-root {
  height: auto !important;
}